
/* You can add global styles to this file, and also import other style files */
.ng2-tag-input__text-input{
  opacity: .5;
  background: #141414;
  color: white;
}
.multiselect-dropdown {
  .dropdown-btn {
    .selected-item {
      margin-right: 4px;
      padding: 0px 5px;
      color: #fff;
      max-width: none !important;
      border-radius: 2px;
      float: left;
      a {
        text-decoration: none;
      }
    }
  }
}


@font-face {
  font-family: 'Nunito';
  src: url("./fonts/Nunito.ttf") format("truetype");
}

*:not(i) {
  font-family: 'Nunito', sans-serif !important;
}
.ta-c{
  text-align: center;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.95) !important;
}
@media (max-width: 600px) {
  .hidden-button{
    display:none!important

  }
  .hidden-desktop{
    display:none!important
  }

  .back{
    padding-right: 3px;
    padding-left: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 5px;
    margin-top: auto;
  }



}

@media (min-width: 600px) {
  .hidden-mobile{
    display:none!important
  }
  .back{
    padding-right: 3px;
    padding-left: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 5px;
    margin-top: auto;
  }

}

.not-exist{
  margin-top: 10px;
  text-align: center;
}


.colorPlaceholder::-webkit-input-placeholder {
  /* Edge */
  color: white !important;
  opacity: 1 !important;
}

.colorPlaceholder::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white !important;
  opacity: 1 !important;
}

.colorPlaceholder::placeholder {
  color: white !important;
  opacity: 1 !important;
}

.pac-item{
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  background-color: #141414;
  font-size: 11px;
  color: #999
}p
.card.bg-dark {
  &.gold-border {
    border: 1px solid #BE9C5E;
  }
  background-color: #23232394 !important;
  border-radius: 10px;
  box-shadow: 0 0.1rem 0.5rem rgb(187 187 187 / 50%) !important;
  &:hover {
    box-shadow: 0 0.1rem 0.8rem rgb(187 187 187 / 50%) !important;
  }

  .card-title {
    font-weight: bolder;
  }

  .card-img-top {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}

.pointer {
  cursor: pointer;
}


/*

.card.bg-dark {
  background-color: #092638 !important;
  * {
    color: #3494da !important;
  }
}
.card.bg-dark {
  background-color: #0093ff2e !important;
}
 */

.form-check-input[type=checkbox] {
  min-width: 15px;
}


.text-3l-dots{
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.text-primary {
  color: #BD9D5F !important;
}

.border-bottom-gold{
  border-bottom: 1px solid  rgb(250, 189, 0, 0.25) !important;
}
/* scrolls */
::-webkit-scrollbar {
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* end scrolls */


.btn-todo-odonto{
  border: #BD9D5F;
  background-color: #BD9D5F;
  color: black;
}


.mr-b{
  margin-bottom: 5px;
}

.chip-filter {
  color: #c6c8cb;
  background-color: #BD9D5F;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding-left: 8px;
  padding-top: 3px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s linear;

  .name{
    overflow: hidden;
    white-space:nowrap;
    color: black;
    text-overflow:ellipsis;
    max-width: 75%;
    display:inline-block;
    margin-top: 2px;
  }

  .closebtn {
    color: black;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    float: right;
    margin-right: 8px;
    background: #BD9D5F;
    width: 22px;
    border-radius: 100%;
    height: 22px;
    text-align: center;
  }

  &.chip-md {
    height: 42px;
    line-height: 42px;
    border-radius: 21px;

  }
}

.floating-box {
  width: 100%;
  text-align: center;
  bottom: 0;
  background: #202529;
  padding: 15px;
}


